<template>
	<div class="footer">
		<div style="background-color:#383d61" class="footer--bg"></div>
		<div class="footer--inner">
			<div class="am-g">
				<div class="am-u-md-3 ">
					<div class="footer_main--column">
						<strong class="footer_main--column_title">产品布局</strong>
						<ul class="footer_navigation">
							<li class="footer_navigation--item"><router-link to="/product" class="footer_navigation--link">扫地机器人</router-link></li>
							<li class="footer_navigation--item"><router-link to="/product" class="footer_navigation--link">擦窗机器人</router-link></li>
<!--							<li class="footer_navigation&#45;&#45;item"><router-link to="/product" class="footer_navigation&#45;&#45;link">观光电梯</router-link></li>-->
						</ul>
					</div>
				</div>
				<div class="am-u-md-6 ">
					<div class="footer_main--column">
						<strong class="footer_main--column_title">关于创源</strong>
						<div class="footer_about">
							<p class="footer_about--text">
                经过十余年的高速发展，创源成长为以清洁服务机器人为主营业务的高新技术企业。产品覆盖家用扫地机器人、商用扫地机器人、擦窗机器人等，产品出口至全球40多个国家，ODM全球出口销量稳居前列。
							</p>
						</div>
					</div>
				</div>
				<div class="am-u-md-3 ">
					<div class="footer_main--column">
						<strong class="footer_main--column_title">联系详情</strong>
						<ul class="footer_contact_info">
							<li class="footer_contact_info--item"><i class="am-icon-phone"></i><span>电话：020-39099982</span></li>
							<li class="footer_contact_info--item"><i class="am-icon-map-marker"></i><span>广东省广州市南沙区</span></li>
							<li class="footer_contact_info--item"><i class="am-icon-clock-o"></i><span>周一 ~ 周日, 8:00 - 23:00 </span></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Footer"
}
</script>

<style scoped>

</style>
