<template>
	<!-- :key="$route.fullPath" 解决了路由前缀相同时跳转不刷新 -->
  <router-view :key="$route.fullPath"/>
</template>

<script>
import Layout from "@/components/common/Layout.vue";
// import { mapActions } from "vuex";


export default {
  name: "App",
  data() {
      return {

      }
  },
  components: {Layout:Layout},
}

</script>