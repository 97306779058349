import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path:'/',
        redirect:'/index_m'
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('../views/IndexView.vue')
    },
    {
        path: '/index_m',
        name: 'index_m',
        component: () => import('../views/IndexView_m.vue')
    },

    {
        path: '/index_en',
        name: 'index_en',
        component: () => import('../views/IndexView_en.vue')
    },

    {
        path: '/index_en_m',
        name: 'index_en_m',
        component: () => import('../views/IndexView_en_m.vue')
    },

    {
        path: '/product',
        name: 'product',
        component: () => import('../views/ProductView.vue'),
    },


    {
        path: '/product/:Id',
        name: 'product',
        component: () => import('../views/ProductView.vue'),
    },

    {
        path: '/product_m',
        name: 'product_m',
        component: () => import('../views/ProductView_m.vue'),
    },

    {
        path: '/product_m/:Id',
        name: 'product_m',
        component: () => import('../views/ProductView_m.vue'),
    },

    {
        path: '/product_en',
        name: 'product_en',
        component: () => import('../views/ProductView_en.vue'),
    },


    {
        path: '/product_en/:Id',
        name: 'product_en',
        component: () => import('../views/ProductView_en.vue'),
    },

    {
        path: '/product_en_m',
        name: 'product_en_m',
        component: () => import('../views/ProductView_en_m.vue'),
    },



    {
        path: '/product_en_m/:Id',
        name: 'product_en_m',
        component: () => import('../views/ProductView_en_m.vue'),
    },

    {
        path: '/productdetails',
        name: 'productdetails',
        component: () => import('../views/ProductDetailsView.vue'),
    },


    {
        path: '/productdetails/:productId',
        name: 'productdetails',
        component: () => import('../views/ProductDetailsView.vue'),
    },

    {
        path: '/productdetails_m',
        name: 'productdetails_m',
        component: () => import('../views/ProductDetailsView_m.vue'),
    },

    {
        path: '/productdetails_m/:productId',
        name: 'productdetails_m',
        component: () => import('../views/ProductDetailsView_m.vue'),
    },

    {
        path: '/productdetails_en',
        name: 'productdetails_en',
        component: () => import('../views/ProductDetailsView_en.vue'),
    },


    {
        path: '/productdetails_en/:productId',
        name: 'productdetails_en',
        component: () => import('../views/ProductDetailsView_en.vue'),
    },

    {
        path: '/productdetails_en_m',
        name: 'productdetails_en_m',
        component: () => import('../views/ProductDetailsView_en_m.vue'),
    },


    {
        path: '/productdetails_en_m/:productId',
        name: 'productdetails_en_m',
        component: () => import('../views/ProductDetailsView_en_m.vue'),
    },

    // {
    //     path: '/product/:productId',
    //     name: 'productDetails',
    //     component: () => import("../views/ProductDetailsView.vue")
    // },
    //
    // {
    //     path: '/product_m/:productId',
    //     name: 'productDetails_m',
    //     component: () => import("../views/ProductDetailsView_m.vue")
    // },
    //
    // {
    //     path: '/product_en_m/:productId',
    //     name: 'productDetails_en_m',
    //     component: () => import("../views/ProductDetailsView_en_m.vue")
    // },



    {
        path: '/example',
        name: 'example',
        component: () => import('../views/ExampleView.vue')
    },

    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('../views/PrivacyView.vue')
    },

    {
        path: '/privacy_m',
        name: 'privacy_m',
        component: () => import('../views/PrivacyView_m.vue')
    },

    {
        path: '/privacy_en',
        name: 'privacy_en',
        component: () => import('../views/PrivacyView_en.vue')
    },

    {
        path: '/privacy_en_m',
        name: 'privacy_en_m',
        component: () => import('../views/PrivacyView_en_m.vue')
    },


    {
        path: '/skill',
        name: 'skill',
        component: () => import('../views/SkillView.vue')
    },

    {
        path: '/skillresearch_m',
        name: 'skillresearch_m',
        component: () => import('../views/SkillView_m.vue')
    },

    {
        path: '/skill_en',
        name: 'skill_en',
        component: () => import('../views/SkillView_en.vue')
    },

    {
        path: '/skillresearch_en_m',
        name: 'skillresearch_en_m',
        component: () => import('../views/SkillView_en_m.vue')
    },

    {
        path: '/skillplace',
        name: 'skillplace',
        component: () => import('../views/Skill_placeView.vue')
    },

    {
        path: '/skillplace_m',
        name: 'skillplace_m',
        component: () => import('../views/Skill_placeView_m.vue')
    },


    {
        path: '/skillplace_en',
        name: 'skillplace_en',
        component: () => import('../views/Skill_placeView_en.vue')
    },

    {
        path: '/skillplace_en_m',
        name: 'skillplace_en_m',
        component: () => import('../views/Skill_placeView_en_m.vue')
    },


    {
        path: '/skillquality',
        name: 'skillquality',
        component: () => import('../views/Skill_qualityView.vue')
    },

    {
        path: '/skillquality_m',
        name: 'skillquality_m',
        component: () => import('../views/Skill_qualityView_m.vue')
    },


    {
        path: '/skillquality_en',
        name: 'skillquality_en',
        component: () => import('../views/Skill_qualityView_en.vue')
    },

    {
        path: '/skillquality_en_m',
        name: 'skillquality_en_m',
        component: () => import('../views/Skill_qualityView_en_m.vue')
    },

    {
        path: '/news',
        name: 'news',
        component: () => import('../views/NewsView.vue')
    },


    {
        path: '/newsdetails',
        name: 'newsdetails',
        component: () => import('../views/NewsDetailsView.vue')
    },



    {
        path: '/news/newsId/:newsId',
        name: 'newsDetails',
        component: () => import('../views/NewsDetailsView.vue')
    },

    {
        path: '/contactbusiness',
        name: 'contactbusiness',
        component: () => import('../views/Contact_businessView.vue')
    },

    {
        path: '/contactbusiness_m',
        name: 'contactbusiness_m',
        component: () => import('../views/Contact_businessView_m.vue')
    },


    {
        path: '/contactbusiness_en',
        name: 'contactbusiness_en',
        component: () => import('../views/Contact_businessView_en.vue')
    },

    {
        path: '/contactbusiness_en_m',
        name: 'contactbusiness_en_m',
        component: () => import('../views/Contact_businessView_en_m.vue')
    },

    {
        path: '/contactexperience',
        name: 'contactexperience',
        component: () => import('../views/Contact_experienceView.vue')
    },

    {
        path: '/contactexperience_m',
        name: 'contactexperience_m',
        component: () => import('../views/Contact_experienceView_m.vue')
    },

    {
        path: '/contactexperience_en',
        name: 'contactexperience_en',
        component: () => import('../views/Contact_experienceView_en.vue')
    },

    {
        path: '/contactexperience_en_m',
        name: 'contactexperience_en_m',
        component: () => import('../views/Contact_experienceView_en_m.vue')
    },

    {
        path: '/contactrecruit',
        name: 'contactrecruit',
        component: () => import('../views/Contact_recruitView.vue')
    },

    {
        path: '/contactrecruit_m',
        name: 'contactrecruit_m',
        component: () => import('../views/Contact_recruitView_m.vue')
    },

    {
        path: '/contactrecruit_en',
        name: 'contactrecruit_en',
        component: () => import('../views/Contact_recruitView_en.vue')
    },

    {
        path: '/contactrecruit_en_m',
        name: 'contactrecruit_en_m',
        component: () => import('../views/Contact_recruitView_en_m.vue')
    },

    {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/ContactView.vue')
    },

    {
        path: '/contact_m',
        name: 'contact_m',
        component: () => import('../views/ContactView_m.vue')
    },

    {
        path: '/contact_en',
        name: 'contact_en',
        component: () => import('../views/ContactView_en.vue')
    },

    {
        path: '/contact_en_m',
        name: 'contact_en_m',
        component: () => import('../views/ContactView_en_m.vue')
    },

    {
        path: '/aboutnews',
        name: 'aboutnews',
        component: () => import('../views/About_newsView.vue')
    },

    {
        path: '/aboutnews_m',
        name: 'aboutnews_m',
        component: () => import('../views/About_newsView_m.vue')
    },


    {
        path: '/aboutnews_en',
        name: 'aboutnews_en',
        component: () => import('../views/About_newsView_en.vue')
    },

    {
        path: '/aboutnews_en_m',
        name: 'aboutnews_en_m',
        component: () => import('../views/About_newsView_en_m.vue')
    },

    {
        path: '/aboutculture',
        name: 'aboutculture',
        component: () => import('../views/About_cultureView.vue')
    },

    {
        path: '/aboutculture_m',
        name: 'aboutculture_m',
        component: () => import('../views/About_cultureView_m.vue')
    },


    {
        path: '/aboutculture_en',
        name: 'aboutculture_en',
        component: () => import('../views/About_cultureView_en.vue')
    },

    {
        path: '/aboutculture_en_m',
        name: 'aboutculture_en_m',
        component: () => import('../views/About_cultureView_en_m.vue')
    },


    {
        path: '/abouthonor',
        name: 'abouthonor',
        component: () => import('../views/About_honorView.vue')
    },

    {
        path: '/abouthonor_m',
        name: 'abouthonor_m',
        component: () => import('../views/About_honorView_m.vue')
    },

    {
        path: '/abouthonor_en',
        name: 'abouthonor_en',
        component: () => import('../views/About_honorView_en.vue')
    },

    {
        path: '/abouthonor_en_m',
        name: 'abouthonor_en_m',
        component: () => import('../views/About_honorView_en_m.vue')
    },


    {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
    },

    {
        path: '/about_m',
        name: 'about_m',
        component: () => import('../views/AboutView_m.vue')
    },

    {
        path: '/about_en',
        name: 'about_en',
        component: () => import('../views/AboutView_en.vue')
    },

    {
        path: '/about_en_m',
        name: 'about_en_m',
        component: () => import('../views/AboutView_en_m.vue')
    }

]

const router = new VueRouter({
    routes
})





export default router

